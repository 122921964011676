import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FcOk, FcCancel } from 'react-icons/fc'
import fileInfo from 'static/file_info.json'
import Table from 'react-bootstrap/Table'
import { reviewHdfFeatures } from 'tools/fileReview.js'
import 'bootstrap/dist/css/bootstrap.css'

const FeatureValidationTable = props => {
  const service = props.service
  const type = props.type
  const review = props.review
  const features = Object.keys(fileInfo[service][type].features)
  const fuelTableRow = (isValid) => (
    <tr>
      <td>Fresh Fuel Isotopics</td>
      <td>
      <Container className='p-0'>
        <Row xs='auto' className='justify-content-center'>
          <Col xs='auto' className='p-0'>
            {
              (isValid)
                ? <FcOk />
                : <FcCancel />
            }
          </Col>
        </Row>
      </Container>
      </td>
      <td>
        <Container className='p-0'>
          <Row xs='auto' className='justify-content-center'>
            <Col xs='auto' className='p-0'>
              --
            </Col>
          </Row>
        </Container>
      </td>
      <td>
        <Container className='p-0'>
          <Row xs='auto' className='justify-content-center'>
            <Col xs='auto' className='p-0'>
              --
            </Col>
          </Row>
        </Container>
      </td>
      <td>
      </td>
    </tr>
  )
  const reviewTableRow = (feature) => (
    <tr>
      <td>{feature}</td>
      <td>
      <Container className='p-0'>
        <Row xs='auto' className='justify-content-center'>
          <Col xs='auto' className='p-0'>
            {
              (review[feature].length > 0)
                ? <FcCancel />
                : <FcOk />
            }
          </Col>
        </Row>
      </Container>
      </td>
      <td>
        <Container className='p-0'>
          <Row xs='auto' className='justify-content-center'>
            <Col xs='auto' className='p-0'>
              {review.points}
            </Col>
          </Row>
        </Container>
      </td>
      <td>
        <Container className='p-0'>
          <Row xs='auto' className='justify-content-center'>
            <Col xs='auto' className='p-0'>
              {review.points - review[feature].length}
            </Col>
          </Row>
        </Container>
      </td>
      <td>
        {
          (review[feature].length > 0)
            ? (<b>Missing Points: </b>)
            : null
        }        
        {
          (review[feature].length > 0)
            ? review[feature].join(', ')
            : null
        }
      </td>
    </tr>
  )
  return (
    (review)
      ? (
          <>
            <Row xs='auto' className='justify-content-center'>
              <Table
                striped
                bordered
                hover
                style={{
                  boxShadow: (
                    '0 10px 20px rgba(0,0,0,0.19),' +
                    ' 0 6px 6px rgba(0,0,0,0.23)'
                  ) 
                }}
              >
                <thead>
                  <tr>
                    <th>Feature</th>
                    <th>
                      <Container className='justify-content-center p-0'>
                        <Row xs='auto' className='justify-content-center'>
                          <Col xs='auto'>
                            Validation
                          </Col>
                        </Row>
                      </Container>
                    </th>
                    <th>Expected Points</th>
                    <th>Points in File</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (
                      props.service === 'eig' 
                      && props.type === 'depletion'
                    )
                      ? fuelTableRow(review.freshData)
                      : null
                  }
                  {features.map(x => reviewTableRow(x))}
                </tbody>
              </Table>
            </Row>
          </>
        )
      : null
  )
}

export default FeatureValidationTable